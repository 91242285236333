<template>
  <div>
    <el-dialog class="dialog" :title="formTitle" :close-on-click-modal="false" :visible.sync="formVisible">
      <el-form ref="dataForm" :rules="rules" :model="formData" label-position="left" label-width="100px" style="width: 400px; margin-left: 50px">
        <el-form-item label="项目分类" prop="typeId">
          <el-select v-model="formData.typeId" filterable default-first-option placeholder="请选择项目分类">
            <el-option v-for="item in typeList" :key="item._id" :label="item.name" :value="item._id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目标题" prop="name">
          <el-input v-model="formData.name" clearable maxlength="60" placeholder="请输入项目标题" />
        </el-form-item>
		<el-form-item label="源码下载" prop="url">
		  <el-input v-model="formData.url" clearable maxlength="60" placeholder="请输入下载地址" />
		</el-form-item>
		<el-form-item label="标签" prop="labelList">
		  <el-checkbox-group v-model="formData.labelList">
		    <el-checkbox @change="change($event,item)" v-for="item in labelList" :label="item._id" :key="item._id">{{item.name}}</el-checkbox>
		  </el-checkbox-group>
		</el-form-item>
        <el-form-item label="图片" prop="image">
          <Imgpond v-model="formData.image" :count="1" />
        </el-form-item>
		<el-form-item label="项目二维码" prop="code">
		  <Imgpond v-model="formData.code" :count="1" />
		</el-form-item>
		<el-form-item label="详情图片" prop="imageList">
		  <Imgpond v-model="formData.imageList" :count="8" />
		</el-form-item>
        <el-form-item label="是否启用" prop="status">
          <el-switch class="has-text" style="margin-left: 6px" v-model="formData.status" :width="36" :active-value="1" :inactive-value="0" active-text="启用" inactive-text="禁用">></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="padding-left: 140px">
        <el-button size="medium" class="confirm-btn" @click="formVisible = false">取消</el-button>
        <el-button size="medium" class="confirm-btn" type="primary" @click="submit">提交</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import http from '@/utils/cloud'
import imageUpload from '@/components/image-upload/image-upload'

export default {
  data() {
    return {
      project: localStorage.getItem('mall-project'),
      formVisible: false, //表单显示状态
      formData: {
		labelList: [],
	  }, //表单数据
	  labelList: [],
      typeList: [],
	  list:[],
      rules: {
		labelList: [{ type: 'array', required: true, message: '请至少添加并选择一个标签', trigger: 'change' }],
		imageList: [{ type: 'array', required: true, message: '请至少上传一张图片', trigger: 'change' }],
        typeId: [{ required: true, message: '请选择项目分类', trigger: 'blur' }],
        name: [{ required: true, message: '请输入项目标题', trigger: 'blur' }],
        url: [{ required: true, message: '请输入下载地址', trigger: 'blur' }],
        status: [{ required: true }],
        image: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        code: [{ required: true, message: '请上传二维码', trigger: 'blur' }]
      }
    }
  },
  computed: {
    formTitle() {
      return this.formData._id ? '修改项目' : '添加项目'
    }
  },
  watch: {
    formVisible(state) {
      if (state) {
        //默认数据
        if (!this.formData._id) {
          this.formData = {
            status: 1,
			labelList:[]
          }
		  this.list = []
        }else{
		  delete this.formData.cate_name
		  this.list = this.formData.labelList;
		  this.formData.labelList = this.formData.labelList.map(item=>{
			return item.id
		  })
		}
      } else {
        this.formData = {
		  labelList:[]
		}
      }
    },
    formData(val) {
    }
  },

  created() {
    this.loadTypeList();
    this.labelTypeList();
  },
  methods: {
	// 选择标签
	change(e,item){
		if(e){
			const res = this.formData.labelList.findIndex(i => i == item._id);
			const data = {}
			data.id = this.formData.labelList[res]
			data.name = item.name
			this.list.push(data);
		}else{
			const res = this.list.findIndex(i => i.id == item._id);
			this.list.splice(res,1);
		}
	},
	//加载标签
	async labelTypeList() {
	  const response = await http.POST('/jw-admin', {
	    module: 'userProject',
	    operation: 'getLabelList',
	    project: JSON.parse(this.project)._id,
		status: 1
	  })
	  this.labelList = response.data
	},
    //加载分类
    async loadTypeList() {
      const response = await http.POST('/jw-admin', {
        module: 'userProject',
        operation: 'getCategoryList',
        project: JSON.parse(this.project)._id,
		status: 1
      })
      this.typeList = response.data
    },
    //提交
    submit() {
      this.$refs.dataForm.validate(async res => {
        if (res === false) {
          return
        }
		this.formData.labelList = this.list;
        const response = await http.POST('/jw-admin', {
          module: 'userProject',
          operation: 'setProject',
          project: JSON.parse(this.project)._id,
          ...this.formData
        })
        if (response.status === 1) {
          this.$message.success(response.msg)
          this.$emit('refreshData')
          this.formVisible = false
        } else {
          this.$message.error(response.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  & ::v-deep .el-dialog__body {
    padding-bottom: 10px;
  }
  & ::v-deep .el-dialog {
    margin-bottom: 5vh;
    min-width: 880px;
    max-width: 880px;
  }
}
</style>
